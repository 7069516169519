.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.447);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 4;
}

.edit-title,
.edit-text {
  margin: 0;
  resize: vertical;
}
.edit-title::-webkit-scrollbar,
.edit-text ::-webkit-scrollbar {
  display: none;
}

.edit-title input {
  width: 100%;
  padding: 1rem;
  font-size: 1.2rem;
  border: 1px solid var(--primary-color);
}

.edit-text {
  padding: 0;
}

.signin-modal {
  min-width: 21rem;
}

.edit-modal .note-nav-btn-left {
  justify-content: flex-start;
}
.edit-modal-btn {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
}

.edit-modal-btn button {
  margin: 0;
}
