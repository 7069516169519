.allNotes {
  display: flex;
}

.pinnedNotes,
.otherNotes {
  display: flex;
  flex-direction: column;
}

.all-notes-container {
  margin-top: 1rem;
}
