body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.body-content {
  width: 80%;
  margin: 4.5rem auto 0;
}

/* Modal */

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.447);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 4;
}

*::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  width: 1rem;
  height: 1rem;
}

*::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
  width: 1rem;
}

@media screen and (max-width: 760px) {
  .body-content {
    width: 95%;
    min-height: 100vh;
  }
}

@media screen and (max-width: 580px) {
  .body-content {
    margin: 3rem auto 0;
  }
}
