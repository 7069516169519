.footer-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0 0;
  padding: 0 10px;
}

.footer-body h3 {
  font-weight: 400;
  font-size: 0.8rem;
  color: var(--title-color);
}

.footer-body a {
  margin-left: 5px;
  font-weight: 500;
  color: var(--primary-color);
}

.footer-title {
  color: var(--title-color);
}

@media screen and (max-width: 400px) {
  .footer-body {
    justify-content: center;
  }

  .github-icon {
    display: none;
  }
}
