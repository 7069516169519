.allNotes {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.note-backdrop {
  position: fixed;
  width: 100%;
  height: 100vh;
  margin-left: 0;
  margin-top: 0;
}

.note-top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 10px;
}

.note-top-section p {
  font-size: 0.7rem;
  position: absolute;
  left: 6px;
  top: 4px;
}

.priority-label {
  padding: 3px 5px;
  color: var(--title-color);
  font-size: 0.9rem;
  background-color: var(--primary-color);
  border-radius: 4px;
  width: 2.5rem;
}

.high-priority {
  background-color: var(--primary-color);
  color: var(--title-color);
}

.low-priority {
  border: 1px solid var(--primary-color);
  background: var(--faint-gray);
  color: var(--text-dark);
}

.pin-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
}

.note-icons {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.note-icons img {
  width: 1.4rem;
  margin: 10px;
}

.note-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 16rem;
  margin: 8px;
  padding: 5px;
  z-index: 1;
  word-wrap: break-word;
  box-shadow: 0 2px 4px var(--card-shadow);
  background-color: transparent;
  outline: 1px solid var(--text-lighter);
}

.note-container .new-note-label {
  padding: 0;
  margin: 5px 0 0;
}

.note-title {
  font-size: 1.1rem;
  padding-left: 6px;
  margin: 1rem 0 5px;
  width: 100%;
}

.note-text {
  padding: 1rem 6px;
  font-size: 0.9rem;
}

.note-text img {
  width: 8rem;
  height: auto;
}

.note-nav-btn {
  margin: 0.6rem 0 0;
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--primary-color);
  position: relative;
}

.note-nav-btn button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  font-size: 0.9rem;
}

.note-labels {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 1rem 7px 0.6rem;
  border-radius: 4px;
  flex-wrap: wrap;
  row-gap: 5px;
}

.note-labels > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 5px;
}

.note-labels p {
  background: transparent;
  margin-right: 8px;
}

@media screen and (max-width: 760px) {
  .note-container {
    width: 14rem;
    margin: 5px 8px;
  }

  .note-nav-btn {
    padding: 5px 0 0;
  }

  .note-nav-btn button {
    padding: 5px;
    border-radius: 50%;
    font-size: 0.9rem;
  }

  .color-palette-icon,
  .trash-icon,
  .archive-icon,
  .edit-icon,
  .option-icon {
    margin: 8px;
  }
}

@media screen and (max-width: 580px) {
  .note-container {
    margin: 5px auto;
  }

  .color-palette-icon,
  .trash-icon,
  .archive-icon,
  .edit-icon,
  .option-icon {
    margin: 5px;
  }
}
