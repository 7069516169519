.left-nav-div {
  width: 16rem;
  padding: 1.5rem 1rem 1rem;
  position: sticky;
  height: 95vh;
  top: 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--nav-bar);
  border-right: 1px solid var(--left-nav-outline);
}

.left-nav-div li {
  display: flex;
  align-items: center;
  color: var(--title-color);
  padding: 5px 1rem;
  margin: 8px 0;
  cursor: pointer;
}

.left-nav-div li h2 {
  font-weight: 500;
  padding: 0.4rem;
  width: 100%;
  font-size: 1rem;
}

.left-nav-div li .nav-icons {
  width: 2.2rem;
  margin-right: 1rem;
  padding: 5px;
}

.user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
}

.user div {
  display: flex;
  align-items: center;
}

.user h2 {
  font-size: 0.9rem;
  margin-left: 8px;
  font-weight: 500;
}

.user button {
  font-size: 1.3rem;
  color: var(--primary-color);
  padding: 8px 10px;
  border-radius: 50%;
  margin: 0;
}

.activeNav {
  border-radius: 4px;
  outline: 1px solid var(--primary-color);
  transition: 0.3s ease-in;
}

@media screen and (max-width: 760px) {
  .left-nav-div {
    width: 100%;
    height: 4rem;
    position: fixed;
    padding: 5px 0;
    top: 100%;
    transform: translate(0, -100%);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .left-nav-div ul {
    display: flex;
  }

  .left-nav-div li {
    margin: auto 5px;
  }

  .left-nav-div li h2 {
    display: none;
  }

  .left-nav-div li .nav-icons {
    width: 2rem;
    margin: auto 0px;
  }

  .user {
    display: none;
  }
}
