:root {
  --dark-bg-color: #1f1f1f;
  --dark-theme-card-bg: #393939;
  --dark-theme-card-hover: rgb(81, 81, 81);
  --dark-theme-new-note: #2f2f2f;
  --card-shadow: rgba(0, 0, 0, 0.136);
  --body-bg: #f4fafb;
  --navbar-shadow: rgba(81, 81, 81, 0.184);
  --dark-nav-bar: #1f1f1f;
}

html[data-pebbleNote-theme="dark"] {
  --bg-color: #1f1f1f;
  --card-bg: #202020;
  --card-hover: rgb(55, 55, 55);
  --new-note-bg: #fdffcc;
  --title-color: #e9e9e9;
  --nav-bar: #1b1b1b;
  --left-nav-outline: rgb(54, 54, 54);
}

html[data-pebbleNote-theme="light"] {
  --bg-color: #f4fafb;
  --card-bg: #ffffff;
  --card-hover: rgb(230, 230, 230);
  --new-note-bg: #ffffff;
  --title-color: #1e1e1e;
  --nav-bar: #ffffff;
  --left-nav-outline: white;
}
