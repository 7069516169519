.desktop-navigation-bar {
  position: fixed;
  width: 100%;
  box-shadow: 0 2px 3px var(--navbar-shadow);
  background-color: var(--nav-bar);
}

.desktop-navigation-bar input {
  background: transparent;
  color: var(--title-color);
}

.mobile-navigation-bar {
  display: none;
}

.logo-icon {
  display: none;
}

@media screen and (max-width: 580px) {
  .desktop-navigation-bar {
    display: none;
  }

  .mobile-navigation-bar {
    position: fixed;
    width: 100%;
    display: flex;
    box-shadow: 0 2px 3px var(--navbar-shadow);
    background-color: var(--nav-bar);
  }

  .mobile-navigation-bar input {
    background: transparent;
    color: var(--title-color);
  }
}
