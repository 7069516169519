.loader-animation {
  background-color: rgba(0, 0, 0, 0.087);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 5;
}

.loader-animation > div {
  width: 40rem;
  margin: auto;
  top: 50%;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
}
