.landing {
  margin: auto;
  width: 80%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 0;
  position: relative;
}

.landing-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto auto 2rem;
}

.landing-header img {
  width: 12rem;
}

.landing-join-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-theme-toggle {
  margin: 0;
  margin-right: 1rem;
}

.landing-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 4rem auto 0;
}

.landing-illustration {
  width: 40rem;
}

.landing-body-header {
  font-size: 2.4rem;
}

.landing-body-header-text {
  font-size: 1.4rem;
  margin: 1rem 0;
}

.landing-body-features {
  padding: 1rem 0;
  margin-top: 1rem;
}

.landing-body-features h3 {
  font-size: 1.7rem;
  font-weight: 500;
}

.landing-body-features ul {
  padding: 2px 0 0;
}

.landing-body-features li {
  font-size: 1.3rem;
  margin: 10px 0;
}

.landing-body-text {
  text-align: left;
  padding: 1rem;
  width: 60%;
}

.landing-nav-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0;
}

.landing-nav-btn p {
  font-size: 1.1rem;
}

.landing-nav-btn > button {
  margin-left: 1rem;
}

@media screen and (max-width: 1080px) {
  .landing {
    width: 100%;
  }

  .landing-body {
    width: 100%;
  }

  .landing-body-header {
    font-size: 2rem;
    margin: 0 1rem 2rem;
  }

  .landing-body-header-text {
    font-size: 1.3rem;
    margin: 0 1rem;
  }

  .landing-body-features h3 {
    font-size: 1.4rem;
    font-weight: 500;
  }
  .landing-body-features li {
    font-size: 1.2rem;
    margin: 10px 0;
  }
}

@media screen and (max-width: 760px) {
  .landing-nav-btn button,
  .landing-join-section button {
    margin: 0 8px;
    font-size: 1.2rem;
  }

  .landing-illustration {
    width: 19rem;
  }

  .landing-body-header {
    font-size: 1.8rem;
  }

  .landing-body-header-text {
    font-size: 1.3rem;
  }

  .landing-body-features h3 {
    font-size: 1.3rem;
  }
  .landing-body-features li {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 580px) {
  .landing {
    margin: auto;
    padding: 0;
    height: 100vh;
  }

  .landing-header {
    padding: 0 8px;
    z-index: 3;
    margin-bottom: 0;
  }

  .landing-join-section button:nth-child(1) {
    font-size: 1rem;
  }

  .landing-join-section button:nth-child(2) {
    margin: auto 5px;
    padding: 8px;
    font-size: 1.2rem;
  }

  .landing-body {
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    width: 100%;
    margin: 0;
  }

  .landing-body-text {
    width: 100%;
  }

  .landing-body-features {
    padding: 1rem;
    margin-top: 1rem;
  }

  .landing-nav-btn {
    justify-content: center;
    margin: 1rem 0 2rem;
    padding: 0;
  }
}
