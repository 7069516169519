.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 100%;
  height: 100vh;
  background-color: var(--bg-color);
  color: var(--title-color);
}

.error-number {
  display: flex;
}

.error-number h1:nth-child(1) {
  transform: rotate(30deg);
}

.error-number h1:nth-child(3) {
  transform: rotate(80deg);
  margin-top: 4rem;
  font-size: 10rem;
}

.page-not-found h1 {
  font-size: 8rem;
  letter-spacing: 2rem;
  background: transparent;
}
