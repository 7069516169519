.new-note-main-container {
  width: 80%;
  margin: 1rem auto;
  background-color: transparent;
  border-radius: 4px;
}

.new-note-input {
  padding: 5px 1rem;
  margin: auto;
  height: auto;
  z-index: 1;
  outline: 1px solid var(--text-lighter);
  border-radius: 4px;
  box-shadow: 0 2px 4px var(--card-shadow);
}

.new-note-top-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
}

.new-note-top-section > input {
  width: 90%;
  border: none;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 3px 0;
  color: var(--title-color);
  background: transparent;
}

.new-note-icons i {
  padding: 0.6rem 0.7rem;
  margin: 0 0.6rem;
  border-radius: 50%;
}

.new-note-top-section .new-note-icons i:nth-child(2) {
  font-size: 1rem;
  padding: 0.8rem 0.8rem;
}

.rich-text-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  border-radius: 4px;
}

.rich-text-editor p {
  background-color: transparent;
}

.rich-text-editor p .text-editor {
  width: 100%;
  border: none;
}

.rich-text-editor img {
  width: 10rem;
  height: auto;
}

.ql-container {
  min-height: 5rem;
}

.ql-container.ql-snow {
  border: none;
}

.ql-toolbar {
  padding-top: 0;
}

.quill {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.ql-toolbar.ql-snow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 0;
  border: none;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
  display: flex;
  gap: 0.25rem;
}

.ql-snow.ql-toolbar:after {
  content: none;
}

.ql-editor {
  font-size: 1.1rem;
}

.ql-snow.ql-toolbar button {
  border-radius: 4px;
}

.ql-snow.ql-toolbar button:hover {
  background-color: var(--btn-hover);
}

.ql-snow.ql-toolbar button.ql-active {
  background-color: var(--btn-hover);
}

.ql-snow.ql-toolbar button.ql-active {
  background-color: var(--btn-hover);
}

.ql-snow.ql-toolbar button:hover .ql-stroke {
  background-color: var(--btn-hover);
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke {
  background-color: var(--btn-hover);
}

.ql-snow.ql-toolbar button:hover .ql-fill {
  background-color: var(--btn-hover);
}

.ql-snow.ql-toolbar button.ql-active .ql-fill {
  background-color: var(--btn-hover);
}

.ql-snow .ql-stroke {
  background-color: var(--btn-hover);
}

.ql-snow .ql-fill {
  background-color: var(--btn-hover);
}

.ql-toolbar .ql-fill {
  stroke: none;
}

.ql-editor.ql-blank::before {
  color: var(--text-lighter);
}

.ql-toolbar .ql-stroke {
  fill: none;
}

.ql-toolbar .ql-stroke {
  fill: none;
}

.new-note-bottom-section {
  display: flex;
  flex-direction: column;
}

.note-nav-btn-left,
.note-nav-btn-right {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.note-nav-btn-left {
  position: relative;
  width: 20rem;
}

.note-nav-btn-left h2 {
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 1rem;
  text-align: center;
  background: transparent;
}

.note-nav-btn-left > button {
  margin-left: 0;
}

.note-nav-btn-right {
  justify-content: flex-end;
  width: 30%;
}

.note-nav-btn-right > button {
  margin: 0;
}

.nav-icons {
  width: 1.6rem;
}

.new-note-bottom-section-icons {
  display: flex;
  align-items: center;
  width: 60%;
}

.new-note-bottom-section-icons i {
  padding: 0.6rem;
  font-size: 0.9rem;
  flex-wrap: wrap;
}

.new-note-bottom-section > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new-note-top-section > input:focus {
  outline: none;
}

.new-note-label {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 1rem 7px 0.6rem;
  border-radius: 4px;
  flex-wrap: wrap;
  row-gap: 5px;
  margin: 10px 0;
}

.new-note-label > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 5px;
}

.new-note-label p {
  background: transparent;
  margin-right: 8px;
}

.set-priority {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.set-priority p {
  margin-right: 5px;
  background-color: transparent;
}

.set-priority select {
  height: 1.6rem;
}

.filter-icon {
  width: 1.7rem;
  margin-left: 8px;
  cursor: pointer;
}

.filter-section {
  position: sticky;
  min-height: 2.5rem;
  z-index: 3;
  margin: 10px auto 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 3px;
  box-shadow: 0 2px 4px var(--card-shadow);
}

.new-note-label {
  outline: 1px solid var(--text-lighter);
}

.filter-section p {
  font-size: 0.8rem;
}

select {
  min-width: 2.5rem;
  height: 1.5rem;
  background: transparent;
  color: var(--title-color);
  border-radius: 4px;
}

.single-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 1px 2px 1px 5px;
  margin: 0 2px;
  font-size: 0.9rem;
  border: 1px solid var(--title-color);
  border-radius: 4px;
}

.single-label i {
  padding: 5px;
  margin-left: 8px;
  font-size: 10px;
}

.alert-error,
.alert-warning-btn {
  margin-left: 0;
}

@media screen and (max-width: 1024px) {
  .new-note-main-container {
    width: 95%;
    margin: 1rem auto;
    background-color: transparent;
    border-radius: 4px;
  }
}

@media screen and (max-width: 760px) {
  .new-note-main-container {
    width: 95%;
  }
}

@media screen and (max-width: 580px) {
  .new-note-input {
    margin-top: 0;
  }
}
