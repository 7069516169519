.body {
  display: flex;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  min-height: 100vh;
  background-color: var(--bg-color);
  color: var(--title-color);
}

@media screen and (max-width: 580px) {
  .body {
    padding: 1rem 0 1rem;
  }
}
